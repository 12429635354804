import React from 'react'
import LayoutComponent from '../../../components/documentation-layout/Layout'

const CreateTestCase = () => {
  return (
    <LayoutComponent>
      <h1>Create a Test Case</h1>
      <p>This guide offers an example of how to create a test case, and it highlights important aspects of the creation process.</p>

      <ol>
        <li>Select a project and click <strong>Test Cases</strong>.</li>
        {/* <li>In the Folders panel, click <strong>New Folder</strong>. Type the name of your folder and click <strong>check</strong>.</li>
        <li>Your folder is saved.</li> */}
        <div class="testimonials">
          <h3>Note</h3>
          <p>You can also click on the existing folder where you want to create a new test case.</p>
        </div>
        <li>Click <strong>+New Test Case</strong> to create a new test case.</li>
        <li>The <strong>Create Test Case</strong> screen appears. On the <strong>Details</strong> tab, add your desired information.</li>
        <div class="testimonials">
          <h3>Note</h3>
          {/* <p>The folder is the destination of your test case. You specified a folder in Step 2.</p> */}
          <p>Status is not related to an execution result. It’s the status of the readiness of the test to be planned and executed.
            {/* Components are obtained from Jira and can be added/managed on the Jira project’s administration page. */}
          </p>
        </div>
        {/* <li>Click the <strong>Test Steps</strong> tab, and fill in the test-case steps.</li>
        <li>Edit and delete options appear when a step is selected.</li> */}


        <li>Click <strong>Save</strong>. The test case saves, and a notification appears on the screen.</li>
      </ol>
      <h3>Fill out the information on the remaining tabs.      </h3>
      <ul>
        <li>On the <strong>Traceability</strong> tab, use <strong>Link</strong> to link an existing issue from Jira to the test case, or use <strong>+</strong> to create and link a new issue to the test case. You can also link Web URLs.</li>
        <li>On the <strong>Execution</strong> tab, you can track previous test-execution results.</li>
        <li>On the <strong>Attachments</strong> tab, you can attach files to the test case.</li>
        <li>On the <strong>Comments</strong> tab, you can add comments to your test cases.</li>
        {/* <li>On the <strong>History</strong> tab, you can track changes done to the test case.</li> */}
      </ul>
    </LayoutComponent>
  )
}

export default CreateTestCase
